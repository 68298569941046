<template>
    <section class="account-search-dropdown">
        <label v-if="label" for="account-select" class="form-label">{{ label }}</label>
        <VueMultiselect id="account-select"
            :options="searchableAccounts"
            label="name"
            v-model="state.selectedAccount"
            placeholder="Type to search..."
            :show-no-results="false"
            :internal-search="false"
            :searchable="true"
            :select-label="null"
            :deselect-label="null"
            :preserve-search="true"
            @search-change="searchChange"
        >
            <template #noOptions="prop">
                ...
            </template>
            <template #option="prop">
                <span class="badge text-bg-primary">{{ prop.option.code }}</span>
                {{ prop.option.name }}
            </template>
        </VueMultiselect>
    </section>
</template>

<script setup lang="ts">
const searchStore = useSearchStore();

const props = defineProps<{
    selected?: IAccount|null;
    clearOnSelect?: boolean;
    label?: string;
}>();

interface IState {
    accounts: IAccount[];
    selectedAccount: IAccount|null;
    loadingAccounts: boolean;
    query: string;
}

const state = reactive<IState>({
    accounts: [],
    selectedAccount: null,
    loadingAccounts: false,
    query: '',
});

onMounted(() => {
    if (props.selected && state.selectedAccount == null) {
        state.selectedAccount = props.selected;
    }
});

watch(() => props.selected, (account) => {
    if (account) {
        state.selectedAccount = account;
    }
});

watch(() => state.selectedAccount, (account: IAccount|null) => {
    if (account) {
        selectAccount(account);
    }
});

const emit = defineEmits<{
    (event: 'update', value: IAccount): void,
}>();

const selectAccount = (account: IAccount) => {
    emit('update', account);

    if (props.clearOnSelect) {
        state.selectedAccount = null;
    }
}

const searchChange = (query: string) => {
    if (query.length < 3) {
        return;
    }

    state.query = query;

    if(searchStore.getAccountSearchResults(query).length === 0) {
        searchAccounts(query);
    }
}

const searchableAccounts = computed<IAccount[]>(() => {
    if(state.loadingAccounts) {
        return [];
    }

    return searchStore.getAccountSearchResults(state.query);
});

const searchAccounts = async (query: string) => {
    state.loadingAccounts = true;

    const { data } = await useApiFetch('/api/accounts?search='+query);
    state.accounts = data.value.data;

    searchStore.addAccountSearchResults(query, data.value.data);

    state.loadingAccounts = false;

};
</script>
