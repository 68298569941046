/**
 * ./stores/SearchStore.ts
 */

export const useSearchStore = defineStore('searches', () => {
    const accountSearchResults = ref<IAccountSearchCollection>({});

    const addAccountSearchResults = (key: string, results: IAccount[]): IAccount[] => {
        accountSearchResults.value[key] = results;

        return accountSearchResults.value[key];
    }

    const getAccountSearchResults = (key: string): IAccount[] => {
        return accountSearchResults.value[key] ?? [];
    }

    const removeAccountSearchResults = (key: string): void => {
        delete accountSearchResults.value[key];
    }

    return {
        addAccountSearchResults,
        getAccountSearchResults,
        removeAccountSearchResults,

    }

});
